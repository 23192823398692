// src/components/Company.js
import React from 'react';
import './Company.css'; // Import the CSS file for Company component
import StickyHeader from './StickyHeader';
import Footer from './Footer';

const Company = () => {
  return (
    <div className="company-page">
      <StickyHeader />
      <main className="company-content">
        <h1>About Us</h1>
        <p>Welcome to MyServiceCircle.App, your trusted platform for connecting clients and service providers across various industries. As a leading solution for managing on-site services, we aim to simplify the way clients interact with their service providers, offering a seamless and efficient experience.</p>

        <h4>About MyServiceCircle.App</h4>
        <p>MyServiceCircle.App is designed to bridge the gap between clients and their preferred service providers, enabling easy access to services in a wide range of fields. Our platform offers a comprehensive suite of tools for managing annual maintenance contracts, service requests, and complaint tracking. With real-time updates and seamless communication, clients and service providers can efficiently manage their interactions and service history.</p>

        <p>Our app stands out by offering:</p>
        <ul>
          <li><strong>Comprehensive Service Management:</strong> From booking requests to tracking service history, MyServiceCircle.App provides all the tools you need to manage your services effectively.</li>
          <li><strong>Real-Time Updates:</strong> Stay informed with instant notifications and updates, ensuring you’re always in the loop.</li>
          <li><strong>Premium Features:</strong> For those looking for more, we offer premium subscriptions with advanced features such as web access, graphical reports, and invoicing capabilities.</li>
        </ul>

        <h4>Parent Company: Meritz Communications</h4>
        <p>MyServiceCircle.App is proudly developed and managed by Meritz Communications, a leader in IT solutions and creative services. With expertise in mobile app development, web solutions, and advertising, Meritz Communications is committed to delivering innovative and reliable technology solutions that empower businesses.</p>

        <h4>Our Vision</h4>
        <p>At MyServiceCircle.App, our vision is to transform the way clients and service providers interact, making service management simpler, faster, and more effective. We strive to create a platform that not only meets the needs of today’s businesses but also anticipates the challenges of tomorrow.</p>

        <h4>Join Us</h4>
        <p>Whether you are a client looking to streamline your service management or a service provider seeking to expand your reach, MyServiceCircle.App is the perfect platform for you. Join our growing community and experience the future of service management today.</p>
      </main>
      <Footer />
    </div>
  );
};

export default Company;
