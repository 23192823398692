// src/components/StickyHeader.js
import React, { useEffect, useState } from 'react';
import './StickyHeader.css'; // Import the CSS file for StickyHeader component
import Navbar from './Navbar';
import logo from '../images/logo.png';

const StickyHeader = () => {
  const [headerClass, setHeaderClass] = useState('header-large');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHeaderClass('header-small');
      } else {
        setHeaderClass('header-large');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`sticky-header ${headerClass}`}>
      <div className="header-content">
        <img src={logo} alt="Logo" className="header-logo" />
        <Navbar />
      </div>
    </header>
  );
};

export default StickyHeader;
