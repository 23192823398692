// src/components/TermsOfUsage.js
import React from 'react';
import './TermsOfUsage.css'; // Import the CSS file for TermsOfUsage component
import StickyHeader from './StickyHeader';
import Footer from './Footer';

const TermsOfUsage = () => {
  return (
    <div className="terms-of-usage-page">
      <StickyHeader />
      <main className="terms-of-usage-content">
        <h1>Terms of Usage</h1>
        <p>Effective Date: [20.06.2024]</p>
        <p>Welcome to MyServiceCircle.App. By accessing or using our platform, you agree to be bound by the following terms and conditions ("Terms of Usage"). If you do not agree with these terms, please do not use our services.</p>

        <h4>1. User Account and Registration</h4>
        <p><strong>1.1 Account Creation:</strong> Users must create an account to access our services. Registration requires OAuth authentication and a secure password, encrypted with a two-layer encryption process.</p>
        <p><strong>1.2 Service Providers:</strong> Must provide location coordinates, tax registration numbers (for invoicing purposes), and complete contact information, including address, email, and phone numbers. Accurate and up-to-date information is required to maintain visibility and functionality within the app.</p>
        <p><strong>1.3 Clients:</strong> Required to provide location information for each product they add, including coordinates and product details with images, to facilitate service interactions.</p>
        <p><strong>1.4 Service Agents:</strong> Must share real-time location data with the app to enable client tracking. Profile pictures are mandatory for service agents and optional for other users.</p>
        <p><strong>1.5 Eligibility:</strong> While no strict age restrictions are enforced, users must comply with applicable local laws regarding age limitations for online services.</p>

        <h4>2. User Responsibilities</h4>
        <p><strong>2.1 Account Security:</strong> Users are responsible for maintaining the confidentiality of their account credentials and are advised to use in-app communication features for privacy protection.</p>
        <p><strong>2.2 Direct Transactions:</strong> Users acknowledge that all financial transactions between clients and service providers occur outside of MyServiceCircle.App’s systems. As such, the platform does not participate in or guarantee any payments or agreements made between parties.</p>
        <p><strong>2.3 No Liability:</strong> MyServiceCircle.App disclaims all liability for the actions, conduct, or services provided by any user, whether a client, service provider, or service agent. Users are encouraged to perform due diligence and verify credentials before engaging with other users.</p>

        <h4>3. Prohibited Activities</h4>
        <p>Users agree not to engage in or facilitate the following activities:</p>
        <ul>
          <li>Any illegal or unauthorized activities, including but not limited to drug trafficking, prostitution, or fraudulent schemes.</li>
          <li>Harassment, abuse, or threats against other users or third parties.</li>
          <li>Misuse of the platform, including spamming, hacking, or distribution of malicious software.</li>
        </ul>

        <h4>4. Intellectual Property</h4>
        <p><strong>4.1 Ownership:</strong> The app's design, concept, logo, color theme, and brand identity are owned by MyServiceCircle.App and protected by applicable intellectual property laws.</p>
        <p><strong>4.2 User Content:</strong> Users may share screenshots for personal use but may not reproduce, distribute, or create derivative works from the app’s content without prior authorization.</p>

        <h4>5. Service Availability and Modifications</h4>
        <p><strong>5.1 Right to Modify:</strong> MyServiceCircle.App reserves the right to modify, suspend, or discontinue any aspect of the app at any time. Users will be notified of significant changes through our website and, where feasible, via email notifications.</p>
        <p><strong>5.2 Refunds:</strong> In the event of permanent service discontinuation, eligible refunds will be processed according to our refund policy.</p>

        <h4>6. Disclaimers and Limitation of Liability</h4>
        <p><strong>6.1 Third-Party Services:</strong> MyServiceCircle.App does not endorse or assume responsibility for the quality, reliability, or accuracy of services provided by third-party providers on our platform.</p>
        <p><strong>6.2 No Warranties:</strong> The app is provided "as-is" and "as-available" without any warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
        <p><strong>6.3 Limitation of Liability:</strong> Under no circumstances shall MyServiceCircle.App, its affiliates, or their respective directors, employees, or agents be liable for any indirect, incidental, consequential, or punitive damages, including lost profits or data, arising from your use of the app.</p>

        <h4>7. Dispute Resolution</h4>
        <p><strong>7.1 Service Provider and Client Disputes:</strong> MyServiceCircle.App is not a party to transactions or agreements between clients and service providers. All disputes arising from such interactions must be resolved independently by the involved parties.</p>
        <p><strong>7.2 Arbitration:</strong> Disputes between users and MyServiceCircle.App shall be resolved through binding arbitration, in accordance with the rules of the Indian Arbitration and Conciliation Act. The arbitration shall be conducted in Mumbai, India, and the decision of the arbitrator shall be final and binding.</p>

        <h4>8. Termination</h4>
        <p><strong>8.1 Suspension and Termination:</strong> MyServiceCircle.App reserves the right to suspend or terminate a user's account at its sole discretion, especially in cases of multiple complaints or breaches of these Terms of Usage. Complaints must be submitted with supporting evidence via email or the help desk.</p>

        <h4>9. Governing Law</h4>
        <p>These terms and conditions shall be governed by and construed in accordance with the laws of India. Users agree to submit to the exclusive jurisdiction of the courts located in Mumbai, Maharashtra, India.</p>

        <h4>Contact Information</h4>
        <p>For questions or concerns about these Terms of Usage, please contact us at support@myservicecircle.app.</p>

        <p>By using MyServiceCircle.App, you agree to comply with these Terms of Usage. We reserve the right to update these terms, and continued use of the app constitutes acceptance of any changes.</p>
      </main>
      <Footer />
    </div>
  );
};

export default TermsOfUsage;
