// src/components/Contact.js
import React from 'react';
import './Contact.css'; // Import the CSS file for Contact component
import StickyHeader from './StickyHeader';
import Footer from './Footer';

const Contact = () => {
  return (
    <div className="contact-page">
      <StickyHeader />
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed/v1/view?key=AIzaSyBxaj3BLFeQAXIGxUL9FtSOqELilzvyjM8&center=18.956216671969596,72.83950648423401&zoom=22&maptype=roadmap"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Meritz Communications Location"
        ></iframe>
      </div>
      <div className="contact-sections">
        <div className="feedback-form">
          <h2>Feedback Form</h2>
          <form>
            <label>
              Name:
              <input type="text" name="name" required />
            </label>
            <label>
              Email:
              <input type="email" name="email" required />
            </label>
            <label>
              Message:
              <textarea name="message" rows="5" required></textarea>
            </label>
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="contact-info">
          <h2>Contact Information</h2>
          <p><strong>Meritz Communications:</strong> (Parent firm of MyServiceCircle.App)</p>
          <p><strong>Address:</strong> 509A, Sterling Chambers, Poona Street, Danabunder, Mumbai - 400009</p>
          <p><strong>Phone:</strong> +91 22 23715220</p>
          <p><strong>Email:</strong> catchus@adhocteam.com</p>
          <p><strong>Hours:</strong> Mon-Fri 9am - 5pm</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
