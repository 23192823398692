// src/components/PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS file for PrivacyPolicy component
import StickyHeader from './StickyHeader';
import Footer from './Footer';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <StickyHeader />
      <main className="privacy-policy-content">
        <h1>Privacy Policy</h1>
        <p>Effective Date: [20.06.2024]</p>
        <p>MyServiceCircle.App ("we," "us," or "our") is committed to protecting the privacy of our users ("you," "your," or "users"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our app, MyServiceCircle.App. Please read this policy carefully to understand our practices regarding your information.</p>
        
        <h4>1. Information We Collect</h4>
        <p><strong>Personal Information:</strong> We may collect personal information such as your name, email address, phone number, and payment information when you register or use our app.</p>
        <p><strong>Service Information:</strong> Information related to the services you provide or request through our app, including service history, preferences, and feedback.</p>
        <p><strong>Device Information:</strong> Information about your device, including IP address, browser type, operating system, and mobile network information.</p>
        <p><strong>Usage Information:</strong> Information about how you use our app, including your interactions with other users and the services you request or provide.</p>
        
        <h4>2. How We Use Your Information</h4>
        <p>We use the information we collect for various purposes, including:</p>
        <ul>
          <li>To facilitate and improve our services</li>
          <li>To process transactions and send you related information</li>
          <li>To provide customer support</li>
          <li>To send you technical notices, updates, security alerts, and support messages</li>
          <li>To monitor and analyze usage and trends to improve user experience</li>
          <li>To detect, investigate, and prevent fraudulent transactions and other illegal activities</li>
          <li>To comply with legal obligations</li>
        </ul>

        <h4>3. Sharing Your Information</h4>
        <p>We do not sell or rent your personal information to third parties. We may share your information with:</p>
        <ul>
          <li><strong>Service Providers:</strong> Third-party vendors, consultants, and service providers who need access to your information to perform work on our behalf.</li>
          <li><strong>Legal Obligations:</strong> When required by law, regulation, legal process, or governmental request.</li>
          <li><strong>Business Transfers:</strong> In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</li>
        </ul>

        <h4>4. Data Security</h4>
        <p>We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure. We cannot guarantee absolute security.</p>

        <h4>5. Your Privacy Rights</h4>
        <p>You may update, correct, or delete your account information at any time by logging into your account. You may also contact us to request access to, correct, or delete any personal information that you have provided to us.</p>

        <h4>6. Third-Party Services</h4>
        <p>Our app may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. Please review their privacy policies before providing them with any personal information.</p>

        <h4>7. Children's Privacy</h4>
        <p>Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete such information.</p>

        <h4>8. Changes to This Privacy Policy</h4>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

        <h4>9. Contact Us</h4>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at support@myservicecircle.app.</p>
        <p>By using MyServiceCircle.App, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
